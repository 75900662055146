<template>
    <el-table v-if="historyData.length > 0" :data="historyData" class="w-full">
        <el-table-column prop="status" width="50">
            <template slot-scope="scope">
                <div class="flex">
                    <el-tooltip
                        v-if="workingStatus(scope.row) && userIsCleaningCompany()"
                        :enterable="false"
                        :openDelay="500"
                        class="item"
                        effect="dark"
                        :content="$t('services.working')"
                        placement="right"
                    >
                        <span class="w-3 h-3 bg-green-500 flex rounded-full" />
                    </el-tooltip>
                    <el-tooltip
                        v-if="shouldFinishStatus(scope.row) && userIsCleaningCompany()"
                        :enterable="false"
                        :openDelay="500"
                        class="item"
                        effect="dark"
                        :content="$t('services.should_finish_already_20_min')"
                        placement="right"
                    >
                        <span class="w-3 h-3 bg-orange-400 flex rounded-full" />
                    </el-tooltip>
                    <el-tooltip
                        v-if="scope.row.status === 2 && userIsCleaningCompany()"
                        :enterable="false"
                        :openDelay="500"
                        class="item"
                        effect="dark"
                        :content="$t('services.didnt_checked_out')"
                        placement="right"
                    >
                        <span class="w-3 h-3 bg-red-500 flex rounded-full" />
                    </el-tooltip>

                    <el-tooltip
                        v-if="userIsBuildingCompany()"
                        :enterable="false"
                        :openDelay="500"
                        class="item"
                        effect="dark"
                        placement="right"
                    >
                        <div slot="content">
                            {{ $t('distance') }}: {{ scope.row.distance_in }}m<br>
                            {{ $t('Accuracy') }}: {{ scope.row.accuracy_in }}m<br>
                        </div>
                        <span
                            class="w-3 h-3  flex rounded-full"
                            :class="{
                                'bg-green-500': scope.row.alert_in === 0,
                                'bg-red-500': scope.row.alert_in === 1,
                            }"
                        />
                    </el-tooltip>
                    <el-tooltip
                        v-if="userIsBuildingCompany()"
                        :enterable="false"
                        :openDelay="500"
                        class="item"
                        effect="dark"
                        :disabled="scope.row.status === 2 || scope.row.status === 0"
                        placement="right"
                    >
                        <div slot="content">
                            {{ $t('distance') }}: {{ scope.row.distance_out }}<br>
                            {{ $t('Accuracy') }}: {{ scope.row.accuracy_out }}<br>
                        </div>
                        <span
                            class="w-3 h-3 ml-1 flex rounded-full"
                            :class="{
                                'bg-gray-300': scope.row.status === 0,
                                'bg-green-500': scope.row.status === 1 && scope.row.alert_out === 0,
                                'bg-red-500': scope.row.status === 1 && scope.row.alert_out === 1,
                                'bg-gray-800': scope.row.status === 2,
                            }"
                        />
                    </el-tooltip>
                </div>
            </template>
        </el-table-column>

        <el-table-column :label="$t('services.client')" prop="client" :show-overflow-tooltip="true">
            <template slot-scope="scope">
                <router-link v-if="userCan('read clients')" :to="`/clients/${scope.row.client_uuid}/dashboard`" class="hover:text-red-500 hover:font-semibold">
                    {{ scope.row.client }}
                </router-link>
                <p v-else>
                    {{ scope.row.client }}
                </p>
            </template>
        </el-table-column>

        <el-table-column :label="$t('services.address')" prop="address" :show-overflow-tooltip="true" />

        <el-table-column :label="$t('services.employee')" prop="employee" :show-overflow-tooltip="true">
            <template slot-scope="scope">
                <router-link :to="`/employees/${scope.row.employee_uuid}/information`" class="hover:text-red-500 hover:font-semibold">
                    {{ scope.row.employee }}
                </router-link>
            </template>
        </el-table-column>

        <el-table-column :label="$t('services.duration')" prop="duration" width="100">
            <template slot-scope="scope">
                {{ `${scope.row.duration_hours}${$t('common.h')} ${scope.row.duration_minutes}${$t('common.m')}` }}
            </template>
        </el-table-column>

        <el-table-column :label="$t('services.planned')" prop="planned" width="100">
            <template slot-scope="scope">
                {{ `${scope.row.planned_hours}${$t('common.h')} ${scope.row.planned_minutes}${$t('common.m')}` }}
            </template>
        </el-table-column>

        <el-table-column :label="$t('services.rate')" width="170">
            <template slot-scope="scope">
                <div class="flex items-center">
                    <el-rate :value="scope.row.rate ? scope.row.rate.rate : 0" @change="handleRatingChange($event, scope.row.id)" />
                    <el-popover
                        v-if="scope.row.rate && scope.row.rate.comment"
                        placement="top-end"
                        :title="$t('services.comment')"
                        width="300"
                        trigger="hover"
                        :content="scope.row.rate.comment"
                    >
                        <span slot="reference" class="flex h-5 w-5 justify-center items-center rounded-full border border-gray-600">
                            <fa-icon :icon="['fas', 'info']" />
                        </span>
                    </el-popover>
                </div>
            </template>
        </el-table-column>

        <el-table-column :label="$t('services.date')" prop="date" width="125" />

        <el-table-column width="75">
            <template slot-scope="scope">
                <el-button-group>
                    <el-button type="primary" size="mini" :disabled="userCanUpdate" @click="handleEdit(scope.row)">
                        <fa-icon :icon="['far', 'edit']" fixed-width />
                    </el-button>

                    <el-button type="danger" size="mini" :disabled="userCanDelete" @click="handleDelete(scope.row.uuid)">
                        <fa-icon :icon="['fas', 'trash']" fixed-width />
                    </el-button>
                </el-button-group>
            </template>
        </el-table-column>
    </el-table>
</template>
<script>
export default {
    props: {
        historyData: {
            type:    Array,
            default: () => [],
        },
    },

    data() {
        return {
            userCanUpdate: !this.userCan('update service history'),
            userCanDelete: !this.userCan('delete service history'),
        };
    },

    computed: {
        userPermissions() {
            return this.$store.state.user.user.permissions;
        },
    },

    watch: {
        userPermissions() {
            this.userCanUpdate = !this.userCan('update service history');
            this.userCanDelete = !this.userCan('delete service history');
        },
    },

    methods: {
        shouldFinishStatus(row) {
            const shouldFinishAt = this.$dayjs(row.date, 'HH:mm DD.MM.YYYY').add(row.planned + 20, 'minute');
            if (row.status === 0 && this.$dayjs() > shouldFinishAt) {
                return true;
            }
            return false;
        },

        workingStatus(row) {
            const shouldFinishAt = this.$dayjs(row.date, 'HH:mm DD.MM.YYYY').add(row.planned + 20, 'minute');
            if (row.status === 0 && this.$dayjs() < shouldFinishAt) {
                return true;
            }
            return false;
        },

        handleEdit(history) {
            this.$emit('handleEdit', history);
        },

        handleDelete(uuid) {
            this.$emit('handleDelete', uuid);
        },

        handleRatingChange(rate, id) {
            this.$emit('handleRatingChange', { id, rate});
        },
    },
};
</script>
